<template>
<div>
  <CHeader fixed light class="border-0">
    <nav class="navbar navbar-expand-lg navbar-light" style="padding-left: 11%; width: 100%;">
        <CRow class="login-header">
          <CIcon
            class="c-sidebar-brand-full mb-3"
            name="logo"
            size="custom-size"
            :height="45"
            viewBox="0 0 550 148"
            style="padding-right: 100px"
          />
        </CRow>
    </nav>
  </CHeader>
  <div class="signup-main">
    <CContainer class="container-form">
      <div class="left">
          <h1 class="section-title">
            {{ $t("SIGN_UP.DESCRIPTION") }}
          </h1>
          <img src="../assets/img/img_hero.png" alt="default.svg" height="336px" width="500px"/>
      </div>
      <div class="right">
        <form class="form">
          <CAlert color="primary" v-if="showPrimaryInfoAlert">
            <CIcon name="cil-check-circle"/>&nbsp;&nbsp;{{ this.alertMsg }}
          </CAlert>
          <CAlert color="danger" v-if="showPrimaryErrorAlert">
            <CIcon name="cil-warning"/>&nbsp;&nbsp;{{ this.alertMsg }}
          </CAlert>
          <div class="fullrow">
              <CInput
                :label="$t('SIGN_UP.FORM.NAME')"
                type="text"
                v-model="name"
                :placeholder="$t('SIGN_UP.FORM.NAME')"
                :is-valid="validateUserName"
                class="col-6"
                :invalid-feedback="$t('SIGN_UP.VALIDATION.NAME')"
                required
              >
              </CInput>
              <CInput
                :label="$t('SIGN_UP.FORM.LAST_NAME')"
                type="text"
                v-model="lastname"
                :placeholder="$t('SIGN_UP.FORM.LAST_NAME')"
                :is-valid="validateUserLastName"
                class="col-6 p-0"
                :invalid-feedback="$t('SIGN_UP.VALIDATION.NAME')"
                required
              >
              </CInput>
          </div>
          <div class="fullrow">
              <CInput
                :label="$t('SIGN_UP.FORM.BUSINESS')"
                :placeholder="$t('SIGN_UP.FORM.BUSINESS')"
                type="text"
                class="col-12 p-0"
                v-model="business_name"
                required
                :is-valid="validateBusinessName"
                :invalid-feedback="$t('SIGN_UP.VALIDATION.BUSINESS')"
              >
              </CInput>
          </div>
          <div class="fullrow">
                <CInput
                :label="$t('SIGN_UP.FORM.EMAIL')"
                type="email"
                v-model="email"
                :placeholder="$t('SIGN_UP.FORM.EMAIL')"
                class="col-12 p-0"
                :invalid-feedback="$t('SIGN_UP.VALIDATION.EMAIL')"
                required
                :is-valid="validateEmail"
                valid-feedback
              >
              </CInput>
          </div>
          <div class="fullrow">
              <CInput
                :label="$t('SIGN_UP.FORM.PASSWORD')"
                type="password"
                v-model="password"
                :placeholder="$t('SIGN_UP.FORM.PASSWORD')"
                class="col-12 p-0"
                required
                :is-valid="validatePassword(password)"
                :invalid-feedback="$t('SIGN_UP.VALIDATION.PASSWORD')"
              >
              </CInput>
          </div>
          <div class="fullrow mt-3">
              <p>
                <input type="checkbox" @click="changeTncStatus" />
                {{ $t("SIGN_UP.FORM.CHECKBOX_TEXT") }}
                <a href="https://durianpay.id/tnc/">{{ $t("SIGN_UP.FORM.TERMS_AND_CONDITIONS") }}</a>
              </p>
          </div>
          <div class="rowcta">
              <CButton
                color="durianprimary"
                class="col-6 col-xl-6 px-4 mb-3"
                @click.prevent="signupMerchant"
              >
                {{ $t("SIGN_UP.BUTTON") }}
              </CButton>
              <p>
                {{ $t("SIGN_UP.EXISTING_ACCOUNT")
                }}<a @click="goTo('/login')">{{ $t("SIGN_UP.SIGNUP") }}</a>
              </p>
          </div>
        </form>
      </div>
    </CContainer>
  </div>
</div>
</template>

<script>
import registerMerchant from "../api/merchant_registration.api.js";
export default {
  name: "SignupView",
  data() {
    return {
      name: null,
      lastname: null,
      email: null,
      business_name: null,
      password: null,
      tnc_checked: false,
      showPrimaryErrorAlert: false,
      showPrimaryInfoAlert: false,
      alertMsg: "",
    };
  },
  methods: {
    changeTncStatus() {
      this.tnc_checked = !this.tnc_checked;
    },
    async signupMerchant() {
      this.showPrimaryErrorAlert = false;
      this.showPrimaryInfoAlert = false;

      if (!this.isPopulated()) {
        this.alertMsg = this.$t("SIGN_UP.FORM.POPULATE_VALIDATION");
        this.showPrimaryErrorAlert = true;
        return;
      }

      if (!(this.validatePassword(this.password) &&
          this.validateBusinessName() &&
          this.validateUserName() &&
          this.validateUserLastName() &&
          this.validateEmail(this.email))
      ) {
        this.alertMsg = this.$t("SIGN_UP.FORM.VALIDATE_ALL");
        this.showPrimaryErrorAlert = true;
        return;
      }

      if (!this.tnc_checked) {
        this.alertMsg = this.$t("SIGN_UP.FORM.ACCEPT_TERMS");
        this.showPrimaryErrorAlert = true;
        return;
      }

      try {
        await registerMerchant(this.name + " " + this.lastname, this.email, this.password, this.business_name);
        this.alertMsg = this.$t("SIGN_UP.FORM.REGISTRATION_SUCCESS");
        this.showPrimaryInfoAlert = true;
        // this.$router.push("/");
      } catch(error) {
        if (error.response) {
          this.alertMsg = error.response.data.error;
        } else {
          this.alertMsg = this.$t("SIGN_UP.FORM.REGISTRATION_FAILED");
        } 
        this.showPrimaryErrorAlert = true;       
      }
    },
    validateUserName() {
      let namePattern = /^[A-Za-z ]+$/;
      return (
        this.name && this.name.length <= 128 && namePattern.test(this.name)
      );
    },
    validateUserLastName() {
      let namePattern = /^[A-Za-z ]+$/;
      return (
        this.lastname && this.lastname.length <= 128 && namePattern.test(this.lastname)
      );
    },
    validateBusinessName() {
      return this.business_name && this.business_name.length <= 128;
    },
    isPopulated() {
      return (
        this.name !== null &&
        this.name.length !== 0 &&
        this.lastname !== null &&
        this.lastname.length !== 0 &&
        this.email !== null &&
        this.email.length !== 0 &&
        this.business_name !== null &&
        this.business_name.length !== 0 &&
        this.password !== null &&
        this.password.length !== 0
      );
    },
  },
};
</script>